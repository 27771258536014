/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'status',
    itemProps: {
      label: '审核状态：' },

    component: {
      name: 'text',
      textStyle: {
        fontWeight: 'bold',
        color: '#618CFF' } },


    valueFilter: function valueFilter() {
      return '审核驳回！';
    } },

  {
    kname: 'desc',
    itemProps: {
      label: '备注说明：' },

    component: {
      name: 'el-input',
      props: {
        type: 'textarea',
        maxlength: 200 } } }];});





// form 默认值
export var formData = function formData() {return {
    li: [{}, {}, {}, {}, {}, {}, {}, {}] };};